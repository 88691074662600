import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Navbar = ({status}) => {
    const [reg,setReg] = useState(false)
    const [search,setSearch] = useState(false)
    const [contact,setContact] = useState(false)
    useEffect(()=>{
        if(status == 'reg'){
            setReg(true)
        }
        if(status == 'search'){
            setSearch(true)
        }
        if(status == 'contact'){
            setContact(true)
        }
    },[])
    return (
        <nav className="navbar navbar-expand-lg sticky-top"> 
            <div className="container">
                <Link className="navbar-brand" to={'/'}>مدرسة الأدڤنتست </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${(reg? 'active' : '')}`} aria-current="page" to={'/'}>صفحة التقديم</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${(search? 'active' : '')}`} to={'/search'}>صفحة الاستعلام عن التقديم</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${(contact? 'active' : '')}`} to={'/contact'}>صفحة التواصل</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;