import axios from "axios";
import Navbar from "./Navbar";
import { useEffect, useState } from "react";
import Loader from './Loader'

const Search = () => {
    const [id, setId] = useState('')
    const [data, setData] = useState('')
    const [search, setSearch] = useState(false)
    const [counter, setCounter] = useState(0)
    const [loader, setLoader] = useState(false)
    const getData = () => {
        setLoader(true)
        axios.post(`${process.env.REACT_APP_HOST_SERVER}api/student`,{id}).then((res) => {
            if (res.data.message === "No student Found") {
                setSearch(true)
                setData('')
                setLoader(false)
            } else {
                setSearch(true)
                setData(res.data)
                console.log(res.data)
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
    }
    useEffect(()=>{
        if(counter > 0){
            getData()
        }
    },[counter])
    return (
        <div className="search-page container">
        <Navbar status='search'/>
        <h1 className="text-center mt-3">أهلا بك في صفحة الاستعلام عن التقديم</h1>
            <div className="form mt-3">
                <input type="text" className="form-control w-75 m-auto text-center" onChange={(e)=>{setId(e.target.value)}} placeholder="ابحث بالرقم القومي للطالب"/>
                {
                    loader ? <div className="text-center"><Loader/></div>:
                <div className="text-center mt-3">
                <button className="w-50 btn btn-warning" onClick={()=>{
                    setCounter(counter+1)
                }}>أستعلم</button>
                </div>
            }
                {
                    search ?
                        data ?
                            <div className="container mt-4 p-4 shadow-lg rounded bg-white">
                                <h2 className="text-center mb-4">تفاصيل الطالب</h2>

                                <div className="row g-3">
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">أسم الطالب رباعي (عربي)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="studentNameAr"
                                            value={data.studentNameAr}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">أسم الطالب رباعي (انجليزي)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="studentNameEn"
                                            value={data.studentNameEn}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">أسم الأم رباعي (عربي)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherNameAr"
                                            value={data.motherNameAr}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">تاريخ ميلاد الطالب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="studentDOB"
                                            value={data.studentDOB.slice(0, 10)}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">جنسية الطالب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="studentNationality"
                                            value={data.studentNationality}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">المحافظة</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="province"
                                            value={data.province}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">المنطقة السكنية</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="residentialArea"
                                            value={data.residentialArea}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">رقم هاتف الأب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fatherPhone"
                                            value={data.fatherPhone}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">رقم هاتف الأم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherPhone"
                                            value={data.motherPhone}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row g-3 mt-4">
                                    <h4 className="mb-3">تفاصيل الوالدين</h4>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">تاريخ ميلاد الأب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fatherDOB"
                                            value={data.fatherDOB.slice(0, 10)}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">تاريخ ميلاد الأم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherDOB"
                                            value={data.motherDOB.slice(0, 10)}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">مؤهل الأب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fatherQualification"
                                            value={data.fatherQualification}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">مؤهل الأم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherQualification"
                                            value={data.motherQualification}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">وظيفة الأب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fatherJob"
                                            value={data.fatherJob}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">وظيفة الأم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherJob"
                                            value={data.motherJob}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">الرقم القومي الخاص بالأب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fatherID"
                                            value={data.fatherID}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">الرقم القومي الخاص بالأم</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="motherID"
                                            value={data.motherID}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">الرقم القومي الخاص بالطالب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="studentID"
                                            value={data.studentID}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">الولاية التعليمية للطالب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="guardian"
                                            value={data.guardian}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">مسؤول مادياً عن الطالب</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="financialResponsible"
                                            value={data.financialResponsible}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">لأي عام دراسي</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="academicYear"
                                            value={data.academicYear}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <label className="form-label">المرحلة الدراسية</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="academicStage"
                                            value={data.academicStage}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row g-3 mt-4">
                                    <h4 className="mb-3">الصور المرفقة</h4>
                                    <div className="col-md-4">
                                        <label className="form-label">صورة بطاقة الأم</label>
                                        <img
                                            src={`${process.env.REACT_APP_HOST_SERVER}images/${data.motherIDImage}`}
                                            alt="Mother ID"
                                            className="img-fluid rounded shadow-sm"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">صورة بطاقة الأب</label>
                                        <img
                                            src={`${process.env.REACT_APP_HOST_SERVER}images/${data.fatherIDImage}`}
                                            alt="Father ID"
                                            className="img-fluid rounded shadow-sm"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">صورة شهادة ميلاد الطالب</label>
                                        <img
                                            src={`${process.env.REACT_APP_HOST_SERVER}images/${data.birthCertificateImage}`}
                                            alt="Birth Certificate"
                                            className="img-fluid rounded shadow-sm"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">صورة للطفل</label>
                                        <img
                                            src={`${process.env.REACT_APP_HOST_SERVER}images/${data.childImage}`}
                                            alt="Child"
                                            className="img-fluid rounded shadow-sm"
                                        />
                                    </div>
                                </div>
                            {
                                    data.specialNeeds ? 
                                        <div className="row g-3 mt-4">
                                            <h4 className="mb-3">الاعاقة</h4>
                                            <div className="col-md-4">
                                                <label className="form-label">اعاقة 1</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="academicStage"
                                                    value={data.specialNeed1}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">اعاقة 2</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="academicStage"
                                                    value={data.specialNeed2}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    : ''
                            }
                            </div>

                                : <h3 className="text-center mt-3">ليس هنالك طلب بهذا الرقم القومي</h3>
                    :''
                }
            </div>
        </div>
    );
}

export default Search;