import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const AdminDashboard = () => {
    const [ID, setID] = useState('')
    const [data, setData] = useState('')
    const [noUsers, setNoUsers] = useState('')
    const navigate = useNavigate('')
    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}api/students/all`).then((res) => {
            if (res.data.message === "No students Found") {
                setNoUsers("No students")
            } else {
                setData(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    } 
    useEffect(()=>{
        getData()
    },[])
    useEffect(() => {
        if (sessionStorage.getItem("id")) {
            axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/check`, {
                id: sessionStorage.getItem("id")
            }).then((response) => {
                if (response.data.message == "not found") {
                    navigate('/adminlogin')
                }
            })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            navigate('/adminlogin')
        }
    },[])
    return (
        <div className="admin">
            <h2 className="text-center fw-light mt-3">Welcome To Admin Dashboard</h2>
            <div className="search px-3">
                <input type="number" className="form-control w-25 text-center" placeholder='Search with St ID' onChange={(e) => {
                    setID(e.target.value)
                }} />
            </div>
            {
                ID ? <table className="table table-striped table-hover table-responsive table-warning mt-3">
                    <thead>
                        <tr className="text-center">
                            <th>Student Name</th>
                            <th>Student Academic Year</th>
                            <th>Student ID</th>
                            <th>Full Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.filter((user)=>user.studentID == ID).map((item) => (
                                <tr className="text-center">
                                    <td>{item.studentNameAr}</td>
                                    <td>{item.studentDOB.slice(0,10)}</td>
                                    <td>{item.studentID}</td>
                                    <td>
                                        <button className="btn btn-warning">
                                            <Link className="text-decoration-none text-light" to={'/fillinfo/' + item.item.studentID}>
                                                Full Info
                                            </Link>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table> : <table className="table table-striped table-hover table-responsive table-warning mt-3">
                    <thead>
                        <tr className="text-center">
                            <th>Student Name</th>
                            <th>Student Academic Year</th>
                            <th>Student ID</th>
                            <th>Full Info</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            data && data.map((item) => (
                                <tr className="text-center">
                                    <td>{item.studentNameAr}</td>
                                    <td>{item.studentDOB.slice(0,10)}</td>
                                    <td>{item.studentID}</td>
                                    <td>
                                        <button className="btn btn-warning">
                                            <Link className="text-decoration-none text-light" to={'/fillinfo/' + item.studentID}>
                                                Full Info
                                            </Link>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </div>
    );
}

export default AdminDashboard;