import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from './Loader'

const AdminLogin = () => {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate('/Dashboard')
    const send = () => {
        setLoader(true)
        axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, {
            admin_name:name,
            admin_password:password
        }).then((response) => {
            console.log(response)
            setLoader(false)
            if (response.data.message == "not found") {
                setError('invalid data')
            } else {
                sessionStorage.setItem('id', response.data)
                navigate('/admindashboard')
            }
        })
            .catch((error) => {
                setLoader(false)
                console.error(error);
            });
    }
    return (
        <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: "90vh" }}>
            <h3>Admin Form</h3>
            <p>Please Fill Data To Be Able To Go To Dashboard</p>
            <form action="" className="w-50">
                <div className="form-div">
                    <label className="form-label">Admin Name</label>
                    <input type="text" onChange={(e) => {
                        setName(e.target.value)
                    }} className="form-control" />
                </div>
                <div className="form-div">
                    <label className="form-label">Admin Password</label>
                    <input type="password" onChange={(e) => {
                        setPassword(e.target.value)
                    }} className="form-control" />
                </div>
                <div className="form-div mt-4">
                    <p className="text-center text-danger">{error}</p>
                </div>
                {
                    loader ? <div className="text-center"><Loader/></div> :
                <div className="text-center">
                    <button className="btn btn-primary w-100 mt-4" onClick={send}>Login</button>
                </div>
                }
            </form>
        </div>
    );
}

export default AdminLogin;