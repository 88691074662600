import { Route, Routes } from "react-router-dom";
import Registration from "./components/Registration";
import AdminLogin from "./components/AdminLogin";
import Search from "./components/Search";
import Contact from "./components/Contact";
import AdminDashboard from "./components/AdminDashboard";
import FullInfo from "./components/FullInfo";
function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" Component={Registration}/> 
          <Route path="search" Component={Search}/> 
          <Route path="contact" Component={Contact}/> 
          <Route path="adminlogin" Component={AdminLogin}/> 
          <Route path="admindashboard" Component={AdminDashboard}/> 
          <Route path="fillinfo/:id" Component={FullInfo}/> 
      </Routes>
    </div>
  );
}

export default App;
