import { useState } from "react";
import Navbar from "./Navbar";
import ContactImg from './asset/contact_img.png';
import axios from "axios";
import Loader from './Loader'

const Contact = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({ name: '', phone: '', message: '' });
    const [sent,setSent] = useState('')
    const [canotSent,setCanotSent] = useState('')
    const [loader, setLoader] = useState(false)
    const validateName = (name) => {
        const regex = /^[\u0600-\u06FF\s]+$/;
        return regex.test(name);
    };

    const validatePhone = (phone) => {
        const regex = /^\d{11}$/;  // Assuming phone number should be exactly 10 digits
        return regex.test(phone);
    };

    const validateMessage = (message) => {
        return message.length > 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        let newErrors = { name: '', phone: '', message: '' };

        if (!validateName(name)) {
            newErrors.name = 'الاسم غير صالح. يجب أن يكون بين 2 و 30 حرفًا.';
            isValid = false;
        }
        if (!validatePhone(phone)) {
            newErrors.phone = 'رقم الهاتف غير صالح. يجب أن يتكون من 11 أرقام.';
            isValid = false;
        }
        if (!validateMessage(message)) {
            newErrors.message = 'الرسالة مطلوبة.';
            isValid = false;
        }

        setErrors(newErrors);

        if (isValid) {
            setLoader(true)
            axios.post(`${process.env.REACT_APP_HOST_SERVER}api/contact`, {
                contact_name:name,
                contact_phone:phone,
                message:message
            }).then((res) => {
                setLoader(false)
                if (res.data.message == "sent") {
                    setSent("تم الارسال بنجاح سيتم التواصل في اقرب وقت")
                }
            }).catch((err) => {
                setLoader(false)
                setCanotSent("حدث خطأ اثناء الارسال")
            })
        }
    };

    return (
        <div className="Contact-page container">
            <Navbar status='contact' />
            <h1 className="text-center mt-4">أهلا بك في صفحة التواصل</h1>
            <div className="d-flex align-items-start mt-5 mt-lg-0 align-items-lg-center justify-content-around" style={{ height: '80vh' }}>
                {
                    sent ? <div className="text-center"> <h4 className="alert alert-success">{sent}</h4></div> :
                    
                <form className="form mt-3" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        className={`form-control m-auto text-center mt-4 ${errors.name ? 'is-invalid' : ''}`}
                        style={{ width: '400px' }}
                        placeholder="الاسم"
                        onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <div className="invalid-feedback text-center">{errors.name}</div>}

                    <input
                        type="number"
                        className={`form-control m-auto text-center mt-4 ${errors.phone ? 'is-invalid' : ''}`}
                        placeholder="رقم الهاتف للتواصل"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    {errors.phone && <div className="invalid-feedback text-center">{errors.phone}</div>}

                    <textarea
                        className={`form-control mt-4 text-center ${errors.message ? 'is-invalid' : ''}`}
                        placeholder="الرسالة"
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    {errors.message && <div className="invalid-feedback text-center">{errors.message}</div>}

                            {
                                loader ? <div className="text-center"><Loader /></div> :
                    <div className="text-center mt-3">
                        <button className="w-75 btn btn-warning text-light" type="submit"> أرسل</button>
                            </div>
                    }
                            {
                                canotSent ? <div className="text-center mt-3">
                                    <h4 className="alert alert-danger">{canotSent}</h4>
                                </div> :''
                            }
                </form>
                }
                <div className="img d-none d-lg-block">
                    <img src={ContactImg} alt="contact-img" className="img-fluid" />
                </div>
            </div>
        </div>
    );
}

export default Contact;
